import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import theme from '../src/theme';
import logger from '../src/utils/logger';

import { getPageData } from '../src/utils/getPageData';
import componentRenderer from '../src/utils/componentRenderer';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(20),
        marginBottom: theme.spacing(18),
    },
}));

export default function NotFoundPage (props) {
    const classes = useStyles(theme);

    React.useEffect(() => {
        logger('error', '404');
    }, []);

    return (
        <Container>
            <div className={classes.root}>
                {
                    componentRenderer(props)
                }
            </div>
        </Container>
    );
}

export async function getStaticProps (context) {
    const { preview, locale, } = context;
    const data = await getPageData('404-seite', locale);

    if (preview) {
        console.info('Fetch preview data for 404 page');
    }

    return { props: data, };
}
